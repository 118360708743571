/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.wave {
  display: inline-block;
  animation-name: wave;
  animation-duration: 0.5s;
  transform-origin:50% 50%;
  animation-iteration-count: 3s;
}

.wave:hover {
  animation-name: wave-again;
  animation-duration: 0.5s;
  transform-origin:50% 50%;
  animation-iteration-count: 3s;
}

.wave:focus {
  animation-name: wave-again;
  animation-duration: 0.5s;
  transform-origin:50% 50%;
  animation-iteration-count: 3s;
}

.wave:active {
  animation-name: wave-again;
  animation-duration: 0.5s;
  transform-origin:50% 50%;
  animation-iteration-count: 3s;
}


@keyframes wave {
  0% { transform: translate(2px, 1px) rotate(0deg); } 
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(2px, 1px) rotate(0deg); }
  30% { transform: translate(0px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-2px, 1px) rotate(0deg); }
  70% { transform: translate(2px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(2px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}



@keyframes wave-again {
  0% { transform: translate(2px, 1px) rotate(0deg); } 
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(2px, 1px) rotate(0deg); }
  30% { transform: translate(0px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-2px, 1px) rotate(0deg); }
  70% { transform: translate(2px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(2px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


.formkit-input {
  @apply min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
}

.formkit-submit {
  @apply flex-none rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;
}

